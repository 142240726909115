import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', 'svcBgCommission', 'fzSvcNumber', function ($scope, $modalInstanse, $modalParams, svcBgCommission, fzSvcNumber) {
    const my = this;

    function round(amount) {
         return fzSvcNumber.ceil5000(amount); // округление до 50 рублей (5000 копеек)
        //return parseInt(amount);
    }

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.state = {
        percent: 0,
        minAmount: 0,
        bankPercent : 0,
        calcDeltaBankPercent : 0,
        maxBankDecrease: 0,
        maxAgentDecrease: 0
    };

    let skip = false;
    /*
    $scope.$watch('my.state.percent', function (newValue) {
        if (skip) {
            skip = false;
            return;
        }
        if (!my.data) return;
        if (!newValue && newValue !== 0) return;
        let newPercent = newValue;

        if (typeof my.data.minPercent !== 'undefined' && my.data.minPercent !== null) {
            const minPercent = parseFloat(my.data.minPercent);
            if (newPercent < minPercent) newPercent = minPercent;
        }

        let newCalcDeltaPercent = fzSvcNumber.percentToNumber(newPercent);
        let newAmount = round(my.data.calcAmount * newCalcDeltaPercent);

        my.state.percent = newPercent;
        my.data.calcDeltaPercent = newCalcDeltaPercent;
        my.data.amount = newAmount;
        skip = (newPercent !== newValue);
    });
*/

    $scope.$watch('my.data.decreaseAgent', function (newValue) {
        if (!my.data) return;
        let newAmount = newValue;
        if (!newAmount) newAmount = 0;
        let decreaseBank = my.data.decreaseBank;
        if (!decreaseBank) decreaseBank = 0;
        my.data.amount = my.data.calcAmount-newAmount-decreaseBank;
    });

    $scope.$watch('my.data.decreaseBank', function (newValue) {
        if (!my.data) return;
        let newAmount = newValue;
        if (!newAmount) newAmount = 0;
        let amountAgent = my.data.decreaseAgent;
        if (!amountAgent) amountAgent = 0;
        my.data.amount = my.data.calcAmount-newAmount-amountAgent;

        let newPercent = fzSvcNumber.round10(myFloor((((my.data.calcAmount-newAmount) / my.data.calcAmount) - 1)*100, -2));
        let newCalcDeltaPercent = fzSvcNumber.percentToNumber(newPercent);
        my.state.bankPercent = newPercent;
        my.state.calcDeltaBankPercent = newCalcDeltaPercent;

        const minPercent = parseFloat(-my.data.minAgentPercent);
        // макс снижение агента - процент от  расчётной комиссии с учётом снижения банка
        my.state.maxAgentDecrease = fzSvcNumber.round10(myFloor((my.data.calcAmount-newAmount) * (minPercent/100)));

    });

    $scope.$watch('my.data.amount', function (newValue) {
        if (skip) {
            skip = false;
            return;
        }

        if (!my.data) return;
        let newAmount = newValue;
        if (!newAmount) newAmount = my.data.calcAmount;

        let newPercent = fzSvcNumber.round10(myFloor(((newAmount / my.data.calcAmount) - 1)*100, -2));
        let newCalcDeltaPercent = fzSvcNumber.percentToNumber(newPercent);

        my.state.percent = newPercent;
        my.data.calcDeltaPercent = newCalcDeltaPercent;
        my.data.amount = newAmount;
        my.data.deviationAmount = newAmount - my.data.calcAmount;            

        skip = (newAmount !== newValue);
    });

    my.isValidPercent = () => {
        // 2020-10-09 не проверяем  общий процент снижения,  проверяем отдельно банк и агента
        return true;
        /*
        return !my.data ||
            !my.data.minPercent ||
            !my.data.calcDeltaPercent ||
            parseInt((my.data.calcDeltaPercent - 1) * 10000) >= parseInt(my.data.minPercent * 100)
        */
    }

    my.isValidBankAmount = () => {
        return !my.data ||
               !my.data.decreaseBank ||
            //!my.data.minPercent ||
            //parseInt((my.state.calcDeltaBankPercent - 1) * 10000) >= parseInt(my.data.minPercent * 100)
            parseInt(my.state.maxBankDecrease) >= parseInt(my.data.decreaseBank) ;
    }

    my.isValidBankAmountNotNegative = () => {
            return !my.data ||
                   !my.data.decreaseBank ||
                //!my.data.minPercent ||
                //parseInt((my.state.calcDeltaBankPercent - 1) * 10000) >= parseInt(my.data.minPercent * 100)
                parseInt(my.data.decreaseBank) >= 0 ;
        }

    my.isValidAgentAmount = () => {
        return !my.data ||
               !my.data.decreaseAgent ||
            parseInt(my.state.maxAgentDecrease) >= parseInt(my.data.decreaseAgent) ;
    }

    my.isValidAgentAmountNotNegative = () => {
            return !my.data ||
                   !my.data.decreaseAgent ||
                 parseInt(my.data.decreaseAgent) >= 0;
        }

    my.isValidAmount = () => {
        return !my.data  ||
            !my.state.minAmount ||
            !my.data.amount ||
            my.data.amount >= my.state.minAmount
    }



    my.isAgent0 = () => {
        return (!my.data.minAgentPercent)
    }

    my.isDecrease = () => {
        return !my.data  ||
            (my.data.decreaseBank || my.data.decreaseAgent)
    }

    my.isValidAmount50 = () => {
        // 2020-04-29 пока уберём
        /*
        return !my.data  ||
            !my.data.amount ||
            my.data.amount == round(my.data.amount)
        */
       return true
    }

    my.isValid = () => {
        return $scope.ngReadonly || (my.isValidAmount() && my.isValidPercent() && my.isValidAmount50());
    }

    function myFloor(value) {
        return Math.floor(value/100)*100;
    }

    function init() {
        my.data = angular.copy($modalParams.item) || {};
        my.state.bgOrder = angular.copy($modalParams.bgOrder) || {};
        my.discount = angular.copy($modalParams.discount) || { "amount": ""}
        /*
        if (typeof my.data.minPercent !== 'undefined' && my.data.minPercent !== null) {
            const minPercent = parseFloat(my.data.minPercent);
            my.state.minAmount = my.data.calcAmount * (1-Math.abs(minPercent/100));         
        }
        else  {
            my.state.minAmount=0;
        }
        */
       my.state.minAmount=0;
        if (!my.data.calcDeltaPercent && my.data.calcDeltaPercent !== 0) my.data.calcDeltaPercent = 1;
        my.state.percent = fzSvcNumber.numberToPercent(my.data.calcDeltaPercent);

        svcBgCommission.calc(my.state.bgOrder, $modalParams.bgOrderParams, $modalParams.company, my.data.frontendServiceLocation).then(commissionAmount => {
            my.data.calcAmount = commissionAmount;
            my.state.percent = fzSvcNumber.round10(myFloor(((my.data.amount / my.data.calcAmount) - 1)*100, -2));
            my.data.calcDeltaPercent = fzSvcNumber.percentToNumber(my.state.percent);
        });
        const minPercent = parseFloat(-my.data.minPercent);
        my.state.maxBankDecrease = fzSvcNumber.round10(myFloor(my.data.calcAmount * (minPercent/100)));
        const minAgentPercent = parseFloat(-my.data.minAgentPercent);        
        my.state.maxAgentDecrease = fzSvcNumber.round10(myFloor(my.data.calcAmount * (minAgentPercent/100)));

    };

    init();
    
}];