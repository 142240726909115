import angular from "angular";

import "./lime.css";

export default angular.module("ext.task-forms", [
  require("@farzoom/fz-c-additional-info"),
  //   require("@farzoom/fz-c-addresses"),
  //    require('@farzoom/fz-c-attributes'),
  require("@farzoom/fz-c-attribute-table"),
  require("@farzoom/fz-c-bank-accounts"),
  require("@farzoom/fz-c-bg-abs-result"),
  require("@farzoom/fz-c-bg-commission"),
  require("@farzoom/fz-c-bki-permission-dates"),
  require("@farzoom/fz-c-board-of-directors"),
  require("@farzoom/fz-c-comments"),
  require("@farzoom/fz-c-company"),
  require("@farzoom/fz-c-company-beneficiaries"),
  require("@farzoom/fz-c-company-buh"),
  require("@farzoom/fz-c-contact-person"),
  require("@farzoom/fz-c-contract-client-signer"),
  require("@farzoom/fz-c-docs"),
  require("@farzoom/fz-c-employee"),
  require("@farzoom/fz-c-fin-report-viewer"),
  require("@farzoom/fz-c-founders"),
  require("@farzoom/fz-c-management"),
  require("@farzoom/fz-c-order"),
  require("@farzoom/fz-c-scoring-result"),
  require("@farzoom/fz-c-select-assignee"),
  require("@farzoom/fz-c-staff-info"),

  // LIME
  require("./fzl-c-addresses").default,
  require("./fzl-c-attributes").default,
  require("./fzl-c-rko-params").default,
  require("./fzl-c-sign-docs").default,
  require("./fzl-c-bg-commission").default,
  require("./fzl-c-kk-commission").default,
  require("./fzl-c-person").default,
  require("./fzl-c-documents").default,
  require("./fzl-c-company-buh").default,
  require("./fzl-c-client-quest").default,
  require("./fzl-c-shareholders").default,
  require("./fzl-verification").default,
  require("./fzl-c-docs-initiator").default,
  require("./fzl-c-contracts").default,
  require("./fzl-c-order-kik").default,
  require("./fzl-c-products-to-connect").default,
  require("./fzl-c-reject-reasons").default,
  require("./fzl-c-return-reasons").default,
  require("./fzl-c-rko-acquiring").default,
  require("./fzl-c-recalculation").default,
  require("./fzl-c-guarantors").default,
  require("./fzl-c-kbg-additional-provision").default,
  require("./fzl-c-additional-requirement").default,
  require("./fzl-c-available-offers").default,
  require("./fzl-c-pre-approve-kik-offers").default,

  // LIME for bg-api-agent-registration
  require("./fzl-form").default,
  require("./fzl-tabs").default,
  require("./fzl-c-product-bg").default,
  require("./fzl-c-product-bg-edit").default,
  require("@farzoom/fz-c-liabilities"),
  require("./fzl-c-liabilities").default,
  require("./fzl-c-management").default,
  require("./fzl-c-order").default,
  require("./fzl-c-scoring-result").default,
  require("./fzl-c-kk-params").default,
]);
