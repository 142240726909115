import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', 'svcBgCommission', 'fzSvcNumber', '$timeout', function ($scope, fzModal, svcBgCommission, fzSvcNumber, $timeout) {
    const my = this;

    // debounce prevents from calling fnc several times in a row
    let timeoutHandler;

    function debounce(fnc, debounceTimeout) {
        if (timeoutHandler) $timeout.cancel(timeoutHandler);
        timeoutHandler = $timeout(fnc, debounceTimeout);
    };

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-bg-commission__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: $scope.model || {},
                bgOrder: $scope.bgOrder || {},
                bgOrderParams: $scope.bgOrderParams || {},
                company: $scope.company || {},
                checkValid: $scope.checkValid || {},
                discount: $scope.discount || {}
            }
        }).closed.then(result => {
            if (!result) return;
            $scope.model = result;
        });
    };

    $scope.$watch('bgOrder', calculate);
    $scope.$watch('bgOrderParams', calculate);
    $scope.$watch('company', calculate);

    function calculate() {
        debounce(() => {
            if (!$scope.model) return;
            svcBgCommission.calc($scope.bgOrder, $scope.bgOrderParams, $scope.company, $scope.model.frontendServiceLocation).then(commissionAmount => {
                $scope.model.calcAmount = commissionAmount;
               // $scope.model.amount = round($scope.model.calcAmount * ($scope.model.calcDeltaPercent || 1));
                let newPercent = fzSvcNumber.round10((($scope.model.amount / $scope.model.calcAmount) - 1)*100, -2);
                let newDeviation = fzSvcNumber.round10($scope.model.amount - $scope.model.calcAmount);
                $scope.model.calcDeltaPercent = fzSvcNumber.percentToNumber(newPercent);
                $scope.model.deviationAmount = fzSvcNumber.percentToNumber(newDeviation);
            });
        }, 300);
    };

    function round(amount) {
        return fzSvcNumber.ceil5000(amount); // округление до 50 рублей (5000 копеек)
        //return parseInt(amount);
    }

    my.isValidPercent = () => {
        if (!$scope.model) return true;
        let minBankPercent = !$scope.model.minPercent || $scope.model.minPercent == null ? 100 : -$scope.model.minPercent ;
        let minAgentPercent = !$scope.model.minAgentPercent || $scope.model.minAgentPercent == null ? 100 : -$scope.model.minAgentPercent ;
        let bankDecrease = !$scope.model.decreaseBank || $scope.model.decreaseBank == null ? 0 : $scope.model.decreaseBank ;
        let agentDecrease = !$scope.model.decreaseAgent || $scope.model.decreaseAgent == null ? 0 : $scope.model.decreaseAgent ;
        let amountFinal = !!$scope.model.amount || $scope.model.amount == null ? 0 : $scope.model.amount ;
        let maxBankDecrease = $scope.model.calcAmount * (minBankPercent/100);
        let maxAgentDecrease = ($scope.model.calcAmount-bankDecrease) * (minAgentPercent/100);



        return (maxBankDecrease >=bankDecrease) && (maxAgentDecrease >=agentDecrease) && bankDecrease >= 0 && agentDecrease >= 0 && amountFinal >= 0;
        /*
        return !$scope.model ||
            !$scope.model.minPercent ||
            !$scope.model.calcDeltaPercent ||
            parseInt(($scope.model.calcDeltaPercent - 1) * 10000) >= parseInt($scope.model.minPercent * 100)
            */

    }

    my.isValidAmount = () => {
        return !$scope.model  ||
            !$scope.model.minAmount ||
            !$scope.model.amount ||
            $scope.model.amount >= parseInt($scope.model.minAmount)
    }

    my.isValidAmount50 = () => {
        // 2020-04-29 пока уберём
        /*
        return !my.data  ||
            !my.data.amount ||
            my.data.amount == round(my.data.amount)
        */
       return true
    }

    my.isCommValid = () => {
        return $scope.ngReadonly || ( // my.isValidAmount() && 
                                      my.isValidPercent() && my.isValidAmount50());
    }

}];

export default ctrl;