
const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.state = {
        indCheckBox: false,
        annCheckBox: false,
        chosenVar: null
    }
    
    $scope.$watch('my.state.indCheckBox', function (indCheckBox) {
        if (indCheckBox) {
            my.state.annCheckBox = false;
            $scope.model.chosenVar = 1;
        } else if (!my.state.annCheckBox) {
            $scope.model.chosenVar = 0;
        }
    });
    
    $scope.$watch('my.state.annCheckBox', function (annCheckBox) {
        if (annCheckBox) {
            my.state.indCheckBox = false;
            $scope.model.chosenVar = 2;
        } else if (!my.state.indCheckBox) {
            $scope.model.chosenVar = 0;
        }
    });
    
        
    
    
  }];
  
  export default ctrl;